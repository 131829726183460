
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public $vuetify: any;
  public scrollTop() {
    this.$vuetify.goTo(0);
  }
  public fab = false;
  public onScroll(e: any) {
    const top = window.pageYOffset || e.target.scrollTop || 0;
    this.fab = top > 50;
  }
}
