import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "../views/Index.vue";
import About from "../views/About.vue";
import Policy from "../views/Policy.vue";
import Sdgs from "../views/Sdgs.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
  },
  {
    path: "/sdgs",
    name: "sdgs",
    component: Sdgs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 100);
    });
  },
});

export default router;
