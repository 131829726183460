
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public menuOpen() {
    const sp_open_box = document.getElementById("sp_open_menu");
    if (sp_open_box) {
      sp_open_box.classList.add("open_menu");
    }
  }
  public menuClose() {
    const sp_close_box = document.getElementById("sp_open_menu");
    if (sp_close_box) {
      sp_close_box.classList.remove("open_menu");
    }
  }
}
