import { render, staticRenderFns } from "./About.vue?vue&type=template&id=4949f4d8&scoped=true&"
import script from "./About.vue?vue&type=script&lang=ts&"
export * from "./About.vue?vue&type=script&lang=ts&"
import style0 from "./About.vue?vue&type=style&index=0&id=4949f4d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4949f4d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
