





































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public keyword = "";
  public noResult = false;

  public makers = [
    {
      id: 1,
      name: "MAUS",
      word:
        "MAUS Maus maus マウス まうす 熱交換器 熱交チューブ プラント チューブプラー チューブプーラー プラントメンテナンス チューブ抜管機 チューブ拡管機 リチューブ リチュービング チューブ引き抜き機 熱交自動製作機 熱交自動加工機 熱交換器自動製作機 全自動 CNC MA 管板穴開先 グルービング加工 フェーシング チューブ溶接 チューブ端面加工 面取り 熱交製作 熱交換器製作",
    },
    {
      id: 2,
      name: "EST",
      word: "EST est プラント保全 プラントメンテナンス プラント保守",
    },
    {
      id: 3,
      name: "PETROVAL",
      word: "PETROVAL petroval ペトロバル ぺとろばる",
    },
    {
      id: 5,
      name: "LADVIK",
      word:
        "LADVIK ladvik ホースバンドクランプ 板バンド 締結部品 燃料ホース ラジエーター ステアリング 自動車部品 バイク 二輪 アフターパーツ 産業機械 医療機器 飲料機器 チューブ カスタムパーツ 分析機 住宅設備 浴槽 トイレ",
    },
    {
      id: 6,
      name: "KALE",
      word:
        "KALE kale カーレ かーれ ネジクランプ ホースバンドクランプ 板バンド ヘビーデューティークランプ 特殊クランプ ネジバンド 締結部品 Vクランプ ウォームドライブ ガーデニング 燃料ホース ラジエーター エアバッグ CVJ ステアリング 自動車部品 バイク 二輪 アフターパーツ 産業機械 医療機器 飲料機器 チューブ カスタムパーツ インタークーラー 住宅設備 浴槽 トイレ",
    },
    {
      id: 7,
      name: "Kenlock",
      word: "ケンロック けんろっく kenlock",
    },
    {
      id: 8,
      name: "TST",
      word: "TST tst",
    },
    // イプロスのページが出来次第実装
    // {
    //   id: 9,
    //   name: "KUNHWA",
    //   word: "KUNHWA kunhwa",
    // },
  ];

  public products = [
    {
      id: 1,
      name: "バンドルエクストラクター",
      maker: "MAUS",
      text:
        "より軽量で柔軟性に富み取り扱いが容易になりました。標準品とは異なるバンドルサイズや重量用のエクストラクターの設計をいたします。",
      image: "./img/maus_bundle_extractor.png",
      link: "https://premium.ipros.jp/techne-co/product/detail/237077004",
      word:
        "ばんどるえくすとらくたー ハイドロエクストラクター ハイドロエキストラクター	チューブバンドル バンドルエクストラクター バンドルエキストラクター バンドル引き抜き機",
    },
    {
      id: 2,
      name: "F-790 1回転マニュアルチューブカッター",
      maker: "MAUS",
      text:
        "低価格で深さは50.8～152.4mm(2″～6″)まで調整可能です。スパナを使った手動での使用を想定して設計され、刃の偏心を利用して動作します。",
      image: "./img/maus_f-790.png",
      link: "",
      word: "f-790 f790 いちかいてんまにゅあるちゅーぶかったー",
    },
    {
      id: 3,
      name: "F/26 セルフセンタリンググルービングツール",
      maker: "MAUS",
      text:
        "管板面から1～12mmの位置でエクステンションキット併用時は300mmの深さに対応。直径9.75～51.50mm(0.384″～2.028″)のサイズがあります。",
      image: "./img/maus_f_26.png",
      link: "https://premium.ipros.jp/techne-co/catalog/detail/325167",
      word:
        "f/26 せるふせんたりんぐぐるーびんぐつーる グルーブ グルービングツール 切削 溝加工",
    },
    {
      id: 4,
      name: "F/751R 管端フェーサー",
      maker: "MAUS",
      text:
        "所定の突出量にトリミングする右回転式カッタです。(仕上げ加工も可)チューブシートの表面を保護しスチール製の回転式ガイドブッシュ／パイロットを使用します。",
      image: "./img/maus_f_751r.png",
      link: "",
      word: "f/751r f751r かんたんふぇーさー",
    },
    {
      id: 5,
      name: "Hydrex 503S 水圧式チューブエキスパンダー",
      maker: "MAUS",
      text:
        "チューブの拡管に最も適した方法です。主な特徴は、『人間工学に基づいて設計された軽量プローブホルダー』などがあります。",
      image: "./img/maus_hydrex_503s.png",
      link: "",
      word:
        "hydrex ハイドレックス はいどれっくす はいどれっくすすいあつしきちゅーぶえきすぱんだー 503s",
    },
    {
      id: 6,
      name: "R/11, R/13シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径1/4″～1/2″(6,35～12,70mm)用の深さ調整可能なチューブエキスパンダーで、フラッシュスラストカラーを備えています。",
      image: "./img/maus_r_11_r_13.png",
      link: "",
      word:
        "r/11 r/13 r11 r13 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 7,
      name: "R/30, R/31シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径1/2″～1.1/2″(12,70〜38,10mm)用の厚さ3/8″～2″(9,5〜50,8mm)までの薄いチューブシート用の深さ調整が可能です。",
      image: "./img/maus_r_30_r_31.png",
      link: "",
      word:
        "r/30 r/31 r30 r31 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 8,
      name: "R/50, R/51シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径1/2″～1.1/2″(12,70〜38,10mm)用の厚さ3/8″～6.3/4″(9,5〜171,5 mm)までの厚いチューブシート用の深さ調整が可能です。",
      image: "./img/maus_r_50_r_51.png",
      link: "",
      word:
        "r/50 r/51 r50 r51 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 9,
      name: "5R/70, 5R/71シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径5/8″〜1.1/2″(15,90〜38,10mm)の薄肉のチューブで、3/8″〜4″の小/中厚さのチューブシートに対応しています。",
      image: "./img/maus_5r_70_5r_71.png",
      link: "",
      word:
        "5r/70 5r/71 5r70 5r71 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 10,
      name: "5R/80, 5R/81シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径5/8″〜1.1/2″(15,90〜38,10mm)の薄肉のチューブで、3/8″〜8″の厚さのチューブシートに対応しています。",
      image: "./img/maus_5r_80_5r_81.png",
      link: "",
      word:
        "5r/80 5r/81 5r80 5r81 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 11,
      name: "R/41シリーズ",
      maker: "MAUS",
      text:
        "チューブ外径1.3/4″～3″(44,45～76,20mm)用のボイラおよび厚いチューブシートの深さ調整が可能です。",
      image: "./img/maus_r_41.png",
      link: "",
      word:
        "r/41 r41 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 12,
      name: "R/141, R161シリーズ",
      maker: "MAUS",
      text:
        "チューブからツールを取り外すことなくシートの厚み全体を拡管できます。チューブ外径5/8″～1″(15,87～25,40mm)のチューブに対応しています。",
      image: "./img/maus_r_141_r161.png",
      link: "",
      word:
        "r/141 r/161 r141 r161 チューブエキスパンダー ちゅーぶえきすぱんだー ロール拡管機 ボイラー セ―ドル ローラー拡管",
    },
    {
      id: 13,
      name: "熱交チューブのメカニカルプラグ工法Pop-A-Plug / ポップ ア プラグ",
      maker: "EST",
      text:
        "熱交チューブを閉止する際にワンタッチで恒久的に閉止できるプラギング工法です。溶接不要ながら最大約40MPaまで対応可能です。",
      image: "./img/est_pop_a_plug.jpg",
      link: "https://premium.ipros.jp/techne-co/product/detail/2000231427",
      word:
        "ねつこうちゅーぶのめかにかるぷらぐこうほう ぽっぷあぷらぐ ポップアプラグ pop-a-plug popaplug 熱交換器 プラグ打ち 閉止プラグ メカニカルプラグ 熱交チューブ ボイラチューブ メカプラグ チューブプラグ 溶接プラグ エアフィンクーラー チューブ閉止 CPI PERMA P2",
    },
    {
      id: 14,
      name: "配管耐圧テストプラグGTシリーズ",
      maker: "EST",
      text:
        "耐圧テスト時のパイプやノズルの端部閉止にプラグの組付けだけで最大96MPaまで使用できる特殊な治具です。溶接閉止が不要になります。",
      image: "./img/est_grip_tight.jpg",
      link: "https://premium.ipros.jp/techne-co/product/detail/2000202956",
      word:
        "はいかんたいあつてすとぷらぐgtしりーず はいかんたいあつてすとぷらぐじーてぃーしりーず 配管 パイプ ボイラチューブ 耐圧テスト 水圧テスト 気密テスト フランジ工事 GT フランジテスト ノズル耐圧",
    },
    {
      id: 15,
      name: "フランジ局所耐圧テストプラグ DBB/ハイリフトシリーズ",
      maker: "EST",
      text:
        "フランジ交換工事後の耐圧テスト時にフランジ溶接点の局所だけをテストできる治具です。(最大耐圧テスト値：15.5MPa)",
      image: "./img/est_dbb.jpg",
      link: "https://premium.ipros.jp/techne-co/product/detail/2000581796",
      word:
        "ふらんじきょくしょたいあつてすとぷらぐ dbb/ハイリフトシリーズ dbb/はいりふとしりーず 配管 パイプ ボイラチューブ 耐圧テスト 水圧テスト 気密テスト フランジ工事 GT フランジテスト ノズル耐圧",
    },
    {
      id: 16,
      name: "熱交換器チューブリークテストガン",
      maker: "EST",
      text:
        "熱交チューブのリークを素早く簡単にチェック出来るエアー駆動式のガンタイプのテスト治具です。",
      image: "./img/est_g160.jpg",
      link: "https://premium.ipros.jp/techne-co/product/detail/2000577672",
      word:
        "g160 G160 ねつこうかんきちゅーぶりーくてすとがん ネツコウカンキチューブリークテストガン 熱交チューブ リークテスト チューブリーク リークテスター",
    },
    {
      id: 17,
      name: "触媒高密度充填サービス Densicat® ",
      maker: "PETROVAL",
      text:
        "業界で広く使用されている最も効果的な技術です。触媒の分布が遠心力によらず、最適で均一な微粒子の分布を実現します。",
      image: "./img/petroval_densicat.png",
      link: "",
      word:
        "しょくばいこうみつどじゅうてんさーびす densicat でんしきゃっと デンシキャット",
    },
    {
      id: 18,
      name: "赤外線カメラでの加熱炉診断サービス",
      maker: "PETROVAL",
      text:
        "表面温度を測定する非破壊検査法です。炉内で起きている潜在的な限界を発見し、赤外線診断の知見から技術的なフィードバックを即時に提供いたします。",
      image: "./img/petroval_ir_thermography.png",
      link: "https://premium.ipros.jp/techne-co/product/detail/2000603616",
      word:
        "せきがいせんかめらでのかねつろしんだんさーびす 赤外線カメラ 非破壊検査法 プラント ダクト検査 サーモグラフィ―",
    },
    {
      id: 19,
      name: "SPIRELF®",
      maker: "PETROVAL",
      text:
        "チューブの両端をワイヤーで固定した振動装置で、チューブ内の流体の流れのエネルギーを振動に変換し、連続的なオンライン機械的洗浄効果を生み出します。",
      image: "./img/petroval_spirelf.png",
      link:
        "https://premium.ipros.jp/techne-co/product/detail/2000583865/?hub=163&categoryId=55008",
      word:
        "spirelf 熱交換器 熱交チューブ プラント ファウリング チューブインサート",
    },
    {
      id: 20,
      name: "TURBOTAL®",
      maker: "PETROVAL",
      text:
        "入口側にある回転装置で連続的なオンラインクリーニングが行われます。アプリケーションは1.0〜2.0m/s(3.2〜6.4ft/s)の間の液体速度に限定されます。",
      image: "./img/petroval_turbotal.png",
      link:
        "https://premium.ipros.jp/techne-co/product/detail/2000583865/?hub=163&categoryId=55008",
      word:
        "turbotal 熱交換器 熱交チューブ プラント ファウリング チューブインサート",
    },
    {
      id: 21,
      name: "FIXOTAL®",
      maker: "PETROVAL",
      text:
        "流体エネルギーを乱流に変換することで製品の停滞を防ぎ、特定の条件下では管壁の沸騰膜を破壊できます。冷却サービスでは減圧残油やHVGOにも使用。",
      image: "./img/petroval_fixotal.png",
      link:
        "https://premium.ipros.jp/techne-co/product/detail/2000583865/?hub=163&categoryId=55008",
      word:
        "fixotal 熱交換器 熱交チューブ プラント ファウリング チューブインサート",
    },
    {
      id: 23,
      name: "イヤークランプ",
      maker: "LADVIK",
      text:
        "360度全周に緊迫力を均等に与え、耐圧・耐振に優れた高性能クランプで内周には段差と隙間がありません。自動車関連等に採用されております。",
      image: "./img/ladvic_ear_clamp.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word:
        "いやーくらんぷ earclamp ear clamp イヤーバンド カシメバンドクランプ オメガクランプ オメガバンド ワンイヤークランプ ステップレスクランプ ステップレスイヤークランプ エアバッグ CVJ オエティカ エチカ",
    },
    {
      id: 24,
      name: "ホースクランプ",
      maker: "LADVIK",
      text:
        "緊迫力が高く、ワンタッチで締付けが可能なためトルク管理が不要です。自動車等の内部にあるホースとパイプを締結し、水漏れ油漏れを防止します。",
      image: "./img/ladvic_hose_clamp.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word:
        "ほーすくらんぷ hoseclamp hose clamp 板クリップ クリップバンド クリップクランプ ガスバンド",
    },
    {
      id: 25,
      name: "WD9 12",
      maker: "KALE",
      text:
        "低トルクで高い締付力締結力と軽量化を実現しました。バリのないエッジがカール状になっており対象物に損傷を無く締付け可能です。",
      image: "./img/kale_wd9_12.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word: "WD9 wd9 12 クランプ くらんぷ",
    },
    {
      id: 26,
      name: "WD IS SW",
      maker: "KALE",
      text:
        "内側にあるバネでホースの熱膨張に追従できます。オプションでスライドする３ヶ所のクリップでホースに固定し、ハウジング部分の位置を設定できます。",
      image: "./img/kale_wd_is_sw.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word: "wd is sw クランプ くらんぷ",
    },
    {
      id: 27,
      name: "ヘビーデューティークランプ",
      maker: "KALE",
      text:
        "耐食性にも優れ、中高圧向けのホースバンドです。新しいスペーサーが統合され、ボルトの緩みがありません。また、溶接スポット及び接触腐食もありません。",
      image: "./img/kale_heavy_duty_clamp.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word: "heavy duty clamp heavydutyclamp クランプ くらんぷ",
    },
    {
      id: 28,
      name: "ケンロックバンド",
      maker: "Kenlock",
      text:
        "軽量で緩みにくく、外部に突起がありません。小スペースでワンタッチで取り付けることができます。",
      image: "./img/kenlock_band.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55015/",
      word:
        "けんろっくばんど くらんぷ ホースバンドクランプ 板バンド 締結部品 燃料ホース ラジエーター エアバッグ CVJ ステアリング 自動車部品 バイク 二輪 アフターパーツ 産業機械 医療機器 飲料機器 チューブ カスタムパーツ インタークーラー 住宅設備 トイレ配管",
    },
    {
      id: 29,
      name: "スイングカップリング",
      maker: "TST",
      text:
        "ストレートな流路が確保されているので、流量・圧力の損失が極めて少なくなります。また、無圧状態でニップル装着が行われるため簡単に装着が可能です。",
      image: "./img/tst_swing_coupling.png",
      link: "https://premium.ipros.jp/techne-co/product/category/55019/",
      word:
        "すいんぐかっぷりんぐ swing coupling swingcoupling カプラー スイングカップリング エアーホース 配管部品 コンプレッサー エアーツール 工具",
    },
    {
      id: 30,
      name: "OETIKER",
      maker: "OETIKER",
      text: "下記よりお問い合わせください。",
      image: "./img/maker_oetiker.jpg",
      link: "",
      word: ["OETIKER", "oetiker", "おえてぃか"],
    },
    // イプロスのページが出来次第実装
    // {
    //   id: 31,
    //   name: "",
    //   maker: "kunhwa",
    //   text: "hogehoge",
    //   image: "",
    //   link: "",
    //   word: [""],
    // },
  ];

  public get filterdProducts() {
    var products = [];
    var makers = [];
    var proLineId = 0;

    for (var i in this.products) {
      var product = this.products[i];
      if (
        product.name.indexOf(this.keyword) !== -1 ||
        product.maker.indexOf(this.keyword) !== -1 ||
        product.word.indexOf(this.keyword) !== -1
      ) {
        products.push(product);
        proLineId = product.id;
      }
      for (var a in this.makers) {
        var maker = this.makers[a];
        if (
          maker.word.indexOf(this.keyword) !== -1 &&
          maker.name === product.maker &&
          maker.word.indexOf(this.keyword) !==
            product.name.indexOf(this.keyword) &&
          maker.word.indexOf(this.keyword) !==
            product.maker.indexOf(this.keyword) &&
          maker.word.indexOf(this.keyword) !==
            product.word.indexOf(this.keyword)
        ) {
          if (proLineId !== product.id) {
            products.push(product);
          }
        }
      }
    }
    if (!products.length) {
      this.noResult = true;
    } else {
      this.noResult = false;
    }
    return products;
  }

  public $vuetify: any;
  public scrollTop() {
    this.$vuetify.goTo(0);
  }
  public fab = false;
  public onScroll(e: any) {
    const top = window.pageYOffset || e.target.scrollTop || 0;
    this.fab = top > 50;
  }

  public clear() {
    this.keyword = "";
    this.noResult = false;
  }
  //プラント系
  public est() {
    this.keyword = "est";
  }
  public petroval() {
    this.keyword = "petroval";
  }
  public maus() {
    this.keyword = "maus";
  }
  //クランプ系
  public kale() {
    this.keyword = "kale";
  }
  public kenlock() {
    this.keyword = "kenlock";
  }
  public ladvik() {
    this.keyword = "ladvik";
  }
  public tst() {
    this.keyword = "tst";
  }

  // oetiker
  public oetiker() {
    this.keyword = "oetiker";
  }

  public kunhwa() {
    // イプロスのページが出来次第実装
    // this.keyword = "kunhwa";
    window.open("./pdf/KUNHWA.pdf");
  }
}
